import * as React from "react";

import { PrefetchedContent } from "src/components/prefetch";
import Breadcrumbs, { getBreadcrumbsContent } from "../components/Breadcrumbs";
import { UnauthAppContent } from "../components/AppContent";
import { SuppressAndroidInstallPrompt } from "../components/SuppressAndroidInstallPrompt";
import { CrisisBanner } from "../components/Banner";
import RootWrapper from "../components/RootWrapper";

//TODO: Type this exactly to match what tal-core passes on.
export interface AppProps {
  children?: React.ReactNode;
  showNativeAppAd?: boolean;
  setupFonts?: boolean;
  showHeader?: boolean | "unauth" | "scoped";
  prefetchContent?: Record<string, PrefetchedContent>;
  enableCrisisBanner?: boolean;
}

export const CMSHeader = ({
  showNativeAppAd = true,
  showHeader = true,
  setupFonts = false,
  prefetchContent = {},
  enableCrisisBanner = true,
}: AppProps) => {
  const breadcrumbsContent = getBreadcrumbsContent(prefetchContent);

  return (
    <RootWrapper setupFonts={setupFonts}>
      <div>
        {enableCrisisBanner && <CrisisBanner />}
        {!showNativeAppAd && <SuppressAndroidInstallPrompt />}
        <UnauthAppContent
          showHeader={showHeader}
          title=""
          actionBar={{ hidden: true }}
          enableCrisisBanner={enableCrisisBanner}
        />
        {breadcrumbsContent && <Breadcrumbs data={breadcrumbsContent} />}
      </div>
    </RootWrapper>
  );
};
